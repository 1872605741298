<!--
 * @Description: 调度信息详情
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-04-22 15:31:47
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'调度信息状态:' + basicInfo.scheduleInfoStatusName"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                @click="lookReason"
                v-if="basicInfo.scheduleInfoStatus == 6"
                >查看异常完成原因</el-button
              >
              <el-button
                type="primary"
                @click="handleFinish"
                v-if="
                  basicInfo.scheduleInfoStatus != 5 &&
                    basicInfo.scheduleInfoStatus != 6 &&
                    basicInfo.scheduleInfoStatus != 7 &&
                    basicInfo.scheduleInfoStatus != 8
                "
                >异常完成</el-button
              >
            </template>
          </detail-title-content>
          <template>
            <div class="baseInfo-content-wrap">
              <!-- 基本信息 start -->
              <div class="baseInfo-box basicForm">
                <div class="edit-title">基本信息</div>
                <el-form label-width="120px" class="editForm" :inline="true">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item label="调度信息ID">
                        {{ basicInfo.scheduleInfoId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="所属任务单">
                        {{ basicInfo.workNo }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <el-form label-width="120px" :inline="true">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item label="指派人">
                        {{ basicInfo.assignorName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="指派时间">
                        {{ basicInfo.assignTime }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <el-form label-width="120px" :inline="true">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item label="工程师">
                        {{ basicInfo.engineerrpName }}
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                      <el-form-item label="是否携带设备">
                        {{ basicInfo.isCarryingEquipment ? "是" : "否" }}
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                </el-form>
                <el-form label-width="120px" :inline="true" class="mbForm">
                  <el-row>
                    <el-col :span="6" v-if="basicInfo.corpContactName">
                      <el-form-item label="客户联系人">
                        {{ basicInfo.corpContactName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="basicInfo.corpContactPhone">
                      <el-form-item label="客户联系人电话">
                        {{ basicInfo.corpContactPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="实施联系人">
                        {{ basicInfo.contactName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="实施联系电话">
                        {{ basicInfo.contactPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="作业地址">
                        {{ basicInfo.insAddress }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="约定时间">
                        {{ basicInfo.taskArrangedate }}
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                      <el-form-item label="备注">
                        {{ basicInfo.remark }}
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="6">
                      <el-form-item label="实施原因">
                        {{ basicInfo.implementationReason }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 基本信息 end -->
              <!-- 调度任务详情 -->
              <actionItem
                :customerId="basicInfo.customerId"
                :scheduleInfoId="scheduleInfoId"
                :taskId="taskId"
                :taskSecondClass="basicInfo.taskSecondClass"
                pageType="schedulingInfo"
              ></actionItem>
            </div>
          </template>
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 查看异常提交原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :dialogLoading="dialogLoading"
      :reasonForm="reasonForm"
      v-if="reasonVisible"
      @handleClose="handleClose"
    ></abnormal-reason>
    <!-- 异常提交输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="2"
      @refreshData="getDetail"
      :scheduleId="basicInfo.scheduleInfoId"
      @handleClose="handleClose"
      v-if="abnormalFinishDialog"
    ></abnormal-finish-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
// import DetailTableTitle from "@/components/detailTableTitle.vue";
import actionItem from "./actionItem.vue";
// import { exportXlsx } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
import { getScheduleInfoDetail, taskInfoAbnormal } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "schedulingInfoDetails",
  data() {
    return {
      loading: false,
      basicInfo: {}, //
      taskId: "", //
      /*****异常提交原因*******/
      dialogLoading: false,
      reasonVisible: false, //异常提交原因
      reasonForm: {},
      dialogTitle: "查看异常原因",
      reasonLabel: "异常原因",
      /****异常提交处理********/
      abnormalFinishDialog: false
    };
  },
  mixins: [base, listPage, listPageReszie],
  components: {
    detailTitleContent,
    actionItem,
    AbnormalFinishDialog,
    AbnormalReason
  },
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  mounted() {},
  computed: {
    btnType() {
      let value = "";
      if (
        this.basicInfo.scheduleInfoStatus == 1 ||
        this.basicInfo.scheduleInfoStatus == 2
      ) {
        value = "yellowBtn";
      }
      if (
        this.basicInfo.scheduleInfoStatus == 3 ||
        this.basicInfo.scheduleInfoStatus == 4
      ) {
        value = "blueBtn";
      }
      if (
        this.basicInfo.scheduleInfoStatus == 5 ||
        this.basicInfo.scheduleInfoStatus == 6 ||
        this.basicInfo.scheduleInfoStatus == 7 ||
        this.basicInfo.scheduleInfoStatus == 8
      ) {
        value = "greenBtn";
      }
      return value;
    }
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.scheduleInfoId = hasParamsRoute.params.scheduleInfoId;
      this.taskId = hasParamsRoute.params.taskId;
      if (this.scheduleInfoId) {
        this.getDetail();
      }
    },
    //查询调度任务信息详情
    async getDetail() {
      try {
        this.loading = true;
        let res = await getScheduleInfoDetail({
          scheduleInfoId: this.scheduleInfoId
        });
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********查看异常提交原因*********/
    //点击查看异常原因
    lookReason() {
      this.reasonVisible = true;
      this.getTaskAbnormal();
    },
    //调度任务---查看异常提交原因
    async getTaskAbnormal() {
      try {
        let res = await taskInfoAbnormal({
          scheduleInfoId: this.basicInfo.scheduleInfoId
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    // 点击异常提交
    handleFinish() {
      this.abnormalFinishDialog = true;
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/schedulingTaskDetails",
          params: {
            refresh: true
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
